<script>
import auth from "@/mixins/auth";
import { wpService } from "@/services/wp";
export default {
  name: "recovery",

  mixins: [auth],

  props: {
    action_id: null,
    action_key: null,
  },

  data() {
    return {
      casted_id: Number(this.action_id),
      casted_key: String(this.action_key),
      pass1: "",
      pass2: "",
      loading: false,
      verified: false,
      errors: null,
      submited: false,
    };
  },

  created() {
    this.verify();
  },

  computed: {
    invalidPassword() {
      let invalidPassword = [];

      if (!this.submited) return [];
      if (this.pass1.length < 8 || this.pass1.length > 24)
        invalidPassword.push("len");

      if (!this.pass1.length || this.pass1 !== this.pass2)
        invalidPassword.push("match");

      return invalidPassword;
    },
  },

  methods: {
    isWrong(key) {
      return this.invalidPassword.indexOf(key) != -1;
    },
    handleError(error) {
      // this.$parent.close();
      let self = this;
      if (error.code == "invalid_key") {
        this.$buefy.dialog.alert({
          title: "Validación fallida",
          message: "Enlace de recuperación de contraseña inválido.",
          // "La contraseña no puede ser restaurada debido a un error de validación.",
          type: "is-danger",
          onConfirm() {
            self.$parent.close();
          },
        });
      } else {
        this.$buefy.dialog.alert({
          message: error.message ? error.message : "Something's not good",
          type: "is-danger",
          onConfirm() {
            self.$parent.close();
          },
        });
      }
    },
    submit() {
      this.submited = true;
      if (this.invalidPassword.length) return;
      this.loading = true;
      const loading = this.$buefy.loading.open();
      let body = {
        action_id: this.casted_id,
        action_key: this.casted_key,
        password: this.pass1,
      };
      wpService.account
        .recoveryComplete(body)
        .then(() => {
          this.$parent.close();
          this.$buefy.dialog.confirm({
            title: "Recuperación exitosa",
            message:
              "Tu contraseña ha sido restaurada. Ya puedes iniciar sesión con tu correo electrónico y tu nueva contraseña.",
            cancelText: "Cerrar",
            confirmText: "Iniciar sesión",
            onConfirm: () => {
              this.authLogin();
            },
          });
        })
        .catch(error => this.handleError(error))
        .finally(() => {
          this.loading = false;
          loading.close();
        });
    },
    verify() {
      this.loading = true;
      const loading = this.$buefy.loading.open();
      let body = {
        action_id: this.casted_id,
        action_key: this.casted_key,
      };
      return wpService.account
        .recoveryVerify(body)
        .catch(error => this.handleError(error))
        .finally(() => {
          this.verified = true;
          this.loading = false;
          loading.close();
        });
    },
  },
};
</script>

<template>
  <div id="authRecovery">
    <section>
      <h2 class="title">Restablecer contraseña</h2>
      <!-- <b-loading :active="loading" /> -->
      <div v-if="!verified">
        verificando...
      </div>
      <div v-else>
        <div class="content">
          <p>
            ¡Casi listos! Solo falta que definas una nueva contraseña con estos
            requisitos:
          </p>
          <ul>
            <li :class="isWrong('len') ? 'has-text-danger' : ''">
              <small>Debe contener de 8 a 24 caracteres</small>
            </li>
            <li :class="isWrong('match') ? 'has-text-danger' : ''">
              <small>Ambas contraseñas deben coincidir</small>
            </li>
          </ul>
        </div>
        <form @submit.prevent="submit">
          <b-field>
            <b-input
              rounded
              expanded
              size="is-medium"
              v-model="pass1"
              type="password"
              password-reveal
              placeholder="Nueva contraseña"
              :disabled="loading"
            />
          </b-field>
          <b-field>
            <b-input
              rounded
              expanded
              size="is-medium"
              v-model="pass2"
              type="password"
              password-reveal
              placeholder="Confirmar contraseña"
              :disabled="loading"
            />
          </b-field>
          <b-button
            native-type="submit"
            type="is-primary"
            :class="{ 'is-loading': loading }"
            size="is-medium"
            rounded
            expanded
            :disabled="loading"
            >Guardar contraseña</b-button
          >
        </form>
      </div>
    </section>
  </div>
</template>
